import React from "react";
import { Box, Grid2, Typography } from "@mui/material";
import Image from "next/image";

const PLAN_NAME = "Plan Ilimitado";
const DURATION = "6 meses";

const Heading = () => (
  <Typography variant="body1">
    Al registrarte en{" "}
    <Typography component="span" color="primary" fontWeight="bold">
      BondUP{" "}
    </Typography>
    <Typography component="span" fontWeight="bold">
      accederás al {PLAN_NAME}{" "}
    </Typography>
    <Typography component="span" fontWeight="bold" color="error">
      Socios Coopeuch{" "}
    </Typography>
    durante{" "}
    <Typography component="span" fontWeight="bold">
      {DURATION}
    </Typography>
    , el cual incluye:
  </Typography>
);

const features = [
  {
    icon: "🎉",
    content: "Acceso ilimitado a panoramas y actividades gratuitas.",
  },
  {
    icon: "🤝",
    content:
      "La oportunidad de conectar con grupos y personas con intereses similares.",
  },
  {
    icon: "🎟️",
    content: "Acceso a eventos exclusivos para socios Coopeuch.",
  },
];

function Landing() {
  return (
    <Box sx={{ m: 2 }}>
      <Grid2 container spacing={2} alignItems="center">
        {/* Primera fila con el espacio vacío y el texto de introducción */}
        <Grid2 size={{ xs: 2 }} key="empty">
          <Box />
        </Grid2>
        <Grid2 size={{ xs: 10 }} key="heading">
          <Heading />
        </Grid2>

        {/* Filas de features */}
        {features.map(({ icon, content }, index) => (
          <React.Fragment key={`feature-${index}`}>
            <Grid2
              size={{ xs: 2 }}
              key={`icon-${index}`}
              sx={{ textAlign: "center" }}
            >
              <Typography variant="h2">{icon}</Typography>
            </Grid2>
            <Grid2 size={{ xs: 10 }} key={`text-${index}`}>
              <Typography variant="body1">{content}</Typography>
            </Grid2>
          </React.Fragment>
        ))}
      </Grid2>
    </Box>
  );
}

function Toolbar() {
  return (
    <Image
      alt="partner-coopeuch"
      src="/partners/signup/coopeuch.png"
      height={50}
      width={150}
    />
  );
}

export { Landing, Toolbar };
