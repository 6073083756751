import { useRouter } from "next/router";
import { useEffect } from "react";

import Browser from "layouts/web/Browser";
import { APP } from "consts";
import SecureSignup from "views/session/secureSignup";
import HelpButton from "components/_MRui/display/HelpButton";

import {
  CoopeuchPartnerContent,
  PentavidaPartnerContent,
  BancochilePartnerContent,
} from "views/session/partnerContent";

export const metadata = {
  title: `Registrate | ${APP.alias}`,
};

const partners = {
  coopeuch: {
    signUpCode: "COOPEUCH",
    ...CoopeuchPartnerContent,
  },
  bancodechile: {
    signUpCode: "BCH",
    ...BancochilePartnerContent,
  },
  cajalosandes: {
    signUpCode: "CLA",
  },
  pentavida: {
    signUpCode: "PENTA",
    ...PentavidaPartnerContent,
  },
};

const partnerKeys = Object.keys(partners);

export default function PartnerPage() {
  const router = useRouter();
  const { partner } = router.query;

  useEffect(() => {
    if (partner && !partnerKeys.includes(partner.toLowerCase())) {
      router.replace("/");
    }
  }, [partner, router]);

  if (!partner || !partnerKeys.includes(partner)) return null;

  const { signUpCode, ...partnerData } = partners[partner];

  return (
    <Browser
      metadata={metadata}
      config={{
        topBar: { hideRegister: true, partnerDecoration: partnerData.Toolbar },
      }}
    >
      <SecureSignup
        partner={{
          name: partner,
          ...partnerData,
        }}
        signUpCode={signUpCode}
      />
      <HelpButton />
    </Browser>
  );
}
